<template>
  <div class="mainBox">
    <el-form label-width="100px" size="small">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <el-form-item label="OfferId(s)" label-width="90px">
            <el-input
              v-model="offerIds"
              placeholder="offerIds: eg. 2206264 or 2206264,1567396"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="OfferName" label-width="170px">
		    <el-input v-model="offerName" placeholder="OfferName: eg. Mobile Strike"></el-input>
		  </el-form-item> -->
          <!-- <el-form-item>
		    <el-checkbox v-model="offerChecked"></el-checkbox>
		  </el-form-item> -->
        </el-col>
        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <el-form-item label="AffiliateId(s)">
            <group-select
              v-model="affiliateIds"
              :data-source="affiliatesGroupList"
              multiple
              collapse-tags
              :loading="affiliateLoading"
              clearable
              filterable
              size="small"
              class="w100"
            />
          </el-form-item>
          <!-- <el-form-item>
			  <el-checkbox v-model="affiliateChecked"></el-checkbox>
			</el-form-item> -->
        </el-col>
        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <el-form-item label="TimeZone" label-width="90px">
            <el-select v-model="timezone" class="w100">
              <el-option
                v-for="item in timezoneOption"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <el-form-item label="Date" label-width="60px">
            <el-date-picker
              v-model="startToEndDate"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              type="daterange"
              class="w100"
              align="right"
              unlink-panels
              range-separator="-"
              start-placeholder="Start Date"
              end-placeholder="End Date"
              :picker-options="pickerOptions"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <el-form-item label="Aff click_id format" label-width="140px">
            <el-input
              v-model="affClickIdName"
              placeholder="pls input clickId's name in redirectUrl,eg: click_id"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <el-form-item label-width="0px">
            <el-button type="primary" @click="downloadPostClick">Download Excel</el-button>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- <el-row>
        <el-col :lg="6" :md="20">
          <label class="labelName">Interval</label>
          <el-form-item label="Month">
            <el-checkbox v-model="monthChecked"></el-checkbox>
          </el-form-item>
        </el-col>
        <el-col :lg="6" :md="20">
          <el-form-item label="Date">
            <el-checkbox v-model="dateChecked"></el-checkbox>
          </el-form-item>
        </el-col>
        <el-col :lg="6" :md="20">
          <el-form-item label="Hour">
            <el-checkbox v-model="hourChecked"></el-checkbox>
          </el-form-item>
        </el-col>
      </el-row> -->
    </el-form>
  </div>
</template>

<script>
  import qs from 'querystring';
  import optionData from '@/assets/js/optionData';
  import GroupSelect from '@/components/GroupSelect';

  import { mapState, mapActions } from 'vuex';
  import dateUtils from '@/utils/dateutils.js';
  import { api } from 'api/billing/postback';
  import { myMixin } from '@/mixins/mixins.js';

  export default {
    components: {
      GroupSelect,
    },
    mixins: [myMixin],
    data() {
      return {
        offerIds: null,
        offerName: null,
        offerChecked: false,
        affiliateIds: null,
        affiliateChecked: true,
        timezone: '+00:00',
        timezoneOption: optionData.timezoneOption,
        // monthChecked: false,
        // dateChecked: true,
        // hourChecked: true,
        affClickIdName: null,
        startToEndDate: '',
      };
    },
    computed: {
      ...mapState('affiliate', {
        affiliatesGroupList: (state) => state.affiliatesGroupList,
        affiliateLoading: (state) => state.affiliateLoading,
      }),
    },
    mounted() {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() + 3600 * 1000 * 24);
      this.startToEndDate = [];
      this.startToEndDate[0] = dateUtils.date2StrYMD(end);
      this.startToEndDate[1] = dateUtils.date2StrYMD(start);
      this.getAffiliateList();
    },
    methods: {
      ...mapActions('affiliate', ['getAffiliateList']),
      downloadPostClick() {
        // if (this.affiliateIdArray != [] && this.affiliateIdArray.length > 0) {
        //   this.affiliateIds = this.affiliateIdArray;
        // }

        // let param = {};
        // let columns = new Array();

        // if (
        //   this.affiliateChecked == true &&
        //   columns.indexOf('affiliateId') == -1 &&
        //   this.affiliateIds != null
        // ) {
        //   param['affiliateIds'] = this.affiliateIds;
        //   columns.push('affiliateId');
        // } else if (
        //   this.affiliateChecked == false &&
        //   columns.indexOf('affiliateId') == -1 &&
        //   this.affiliateIds == null
        // ) {
        //   var affiliateIdIndex = columns.findIndex((item) => {
        //     if (item == 'affiliateId') {
        //       return true;
        //     }
        //   });
        //   columns.splice(affiliateIdIndex, 1);
        // }

        // if (
        //   this.offerChecked == true &&
        //   columns.indexOf('offerId') == -1 &&
        //   this.offerIds != null
        // ) {
        //   param['offerIds'] = this.offerIds;
        //   columns.push('offerId');
        // } else if (
        //   this.affiliateChecked == false &&
        //   columns.indexOf('offerId') !== -1 &&
        //   this.offerIds == null
        // ) {
        //   var offerIdIndex = columns.findIndex((item) => {
        //     if (item == 'offerId') {
        //       return true;
        //     }
        //   });
        //   columns.splice(offerIdIndex, 1);
        // }
        const param = {};
        if (this.offerIds) {
          param['offerIds'] = this.offerIds;
        }

        if (this.affiliateIds) {
          param['affiliateIds'] = this.affiliateIds.join(',');
        }
        if (this.affClickIdName) {
          param['affClickIdName'] = this.affClickIdName;
        }

        param['fromDate'] = this.startToEndDate[0];
        param['toDate'] = this.startToEndDate[1];

        if (this.timezone) {
          param['timezone'] = this.timezone;
        }

        const strParam = qs.stringify(param);
        console.log(strParam);
        window.open(`${api.DOWNLOADE_CLICK_BILL}?${strParam}`, '_blank');
      },
    },
  };
</script>

<style></style>

<template>
  <div class="postbackBilling">
    <!-- <div class="main-Title bgff"><h2>Postback Buckle Billing</h2></div> -->
    <div class="mainBox">
      <el-tabs type="card" v-model="activeName" class="bgff">
        <el-tab-pane label="Filter" name="first">
          <filter-bill />
        </el-tab-pane>
        <el-tab-pane label="Download Post Back Log" name="second">
          <download-log />
        </el-tab-pane>
        <el-tab-pane label="Give To Channel (Postback)" name="third">
          <give-to-channel />
        </el-tab-pane>
        <el-tab-pane label="API Track Offer" name="forth">
          <api-track-offer />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
  import dateUtils from '@/utils/dateutils.js';
  import FilterBill from './components/postback/FilterBill.vue';
  import DownloadLog from './components/postback/DownloadLog.vue';
  import GiveToChannel from './components/postback/GiveToChannel.vue';
  import ApiTrackOffer from './components/postback/ApiTrackOffer.vue';
  export default {
    components: {
      FilterBill,
      DownloadLog,
      GiveToChannel,
      ApiTrackOffer,
    },
    // components: {
    //   Upload,
    // },
    data() {
      return {
        activeName: 'first',
        queryColumns:
          'click_id|date|adjusted|affiliate_id|aff_sub|offer_id|original_url|redirect_url',
        uploadloading: false,
        file: null,

        doUpload: '/trans/dataexport/buckle/downloadBill',

        causemsg: {},
        affiliateIdArray: [],
      };
    },
    mounted() {
      //默认选择
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() + 3600 * 1000 * 24);
      this.startToEndDate = [];
      this.startToEndDate[0] = dateUtils.date2StrYMD(end);
      this.startToEndDate[1] = dateUtils.date2StrYMD(start);
    },
    methods: {
      familyTree(arr) {
        let temp = [];
        for (let i in arr) {
          var item = arr[i];
          if (item.nextCause != undefined) {
            temp.push(item.nextCause);
            item.nextCause = temp;
            this.familyTree(temp);
          }
        }
      },

      selectAll(val) {
        this.affiliateIdArray = [];
        for (let i = 0; i < val.length; i++) {
          for (let j = 0; j < JSON.parse(val[i]).length; j++) {
            this.affiliateIdArray.push(JSON.parse(val[i])[j]);
          }
        }
      },
    },
  };
</script>
<style lang="scss">
  .el-tree-node {
    white-space: 0;
    outline: 0;
  }
  .el-tree {
    font-size: 14px;
    margin-bottom: 10px;
  }
</style>
<style lang="scss" scoped>
  .trackOfferItem {
    font-size: 18px;
  }
  .upload-demo {
    width: 360px;
  }
</style>
